import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import SecretLayout from "../components/SecretLayout";
// import { H2 } from "../components/SecretComponents";
import * as styles from "../components/post.module.css";
import { H2 } from "../components/Logo";
import { MDXProvider } from "@mdx-js/react";

const MyP = (props) => {
  return <p {...props} style={{ margin: "1rem 0" }} />;
};

const components = {
  p: MyP,
};

const Post = ({ data }) => {
  const date = data.mdx.frontmatter.date;
  const title = data.mdx.slug;
  const arr = [...Array(data.allMdx.totalCount).keys()].map((i) => i + 1);
  const Date = ({ children }) => {
    return <span className={`${styles.date}`}>{children}</span>;
  };
  // view Readme.md for more information
  const Navigate = () => {
    return (
      <ul className={styles.navUl}>
        {arr.map((i) => (
          <li key={i} className={styles.li}>
            <Link to={`/secret/${i}`} className={styles.link}>
              {i}
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  return (
    <SecretLayout>
      <Navigate />
      <div>
        <H2 text={title} />
      </div>
      <Date>{date}</Date>
      <div className={styles.fontSmaller}>
        <MDXProvider components={components}>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
      <div style={{ margin: "160px 0 80px" }}>
        <Navigate />
      </div>
    </SecretLayout>
  );
};

export default Post;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date
      }
      slug
    }
    allMdx(filter: { fields: { sourceInstanceName: { eq: "diary" } } }) {
      totalCount
    }
  }
`;
